<template>
  <div>
    <navigation :msg="msg"></navigation>
    <div class="topmsg">
      <div class="moneyclass">
        <div><img src="../../assets/moneyimg.png" /></div>
        <div>账户余额</div>
      </div>
      <div class="mon"><span>￥</span>{{cash.toLocaleString()}}</div>
      <div class="incomeclass">
        <div class="mones">
          <div>累计收益</div>
          <div>{{income_money=="0"?'0.00':income_money.toLocaleString()}}</div>
        </div>
        <div class="mones">
          <div>可提现收益</div>
          <div>{{cash.toLocaleString()}}</div>
        </div>
        <div class="mones">
          <div>近七日收益</div>
          <div>{{last_money=="0"?'0.00':last_money.toLocaleString()}}</div>
        </div>
      </div>
    </div>
    <div class="mymsg">
      <div @click="$router.push('/withdraw')" class="myme">
        <div class="my2">
          <img src="../../assets/my1.png" />
          <div>余额提现</div>
        </div>
        <div>
          <van-icon name="arrow" />
        </div>
      </div>
      <div @click="$router.push('/withdrawrecording')" class="myme">
        <div class="my2">
          <img src="../../assets/my2.png" />
          <div>提现记录</div>
        </div>
        <div>
          <van-icon name="arrow" />
        </div>
      </div>
      <div @click="$router.push('/incomerecording')" class="myme">
        <div class="my2">
          <img src="../../assets/my3.png" />
          <div>收益明细</div>
        </div>
        <div>
          <van-icon name="arrow" />
        </div>
      </div>
      <div @click="$router.push('/accountsetting')" class="myme">
        <div class="my2">
          <img src="../../assets/my5.png" />
          <div>提现设置</div>
        </div>
        <div>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
export default {
  components: {
    navigation,
  },
  data() {
    return {
      msg: "我的账户",
      income_money:"", //累计收入
      cash:"", //可用余额
      last_money:"", //上月收益
    };
  },
  mounted(){
    this.selecthomemsg();
  },
  methods: {
    /**
     * 查询账户信息
     */
    selecthomemsg() {
      this.$http
        .post("/firm/v1/Channel/get_account", {
          reqType: "userinfo",
        })
        .then((res) => {
           
          let themoney = JSON.parse(res.data).data;
          this.income_money = themoney.income_money;
          this.cash = themoney.cash;
          this.last_money = themoney.last_money;
        });
    },
  },
};
</script>
<style scoped>

.my2 {
  display: flex;
  align-items: center;
}
.my2 img {
  width: 0.4rem;
  height: 0.34rem;
  margin-right: 0.27rem;
}
.myme {
  display: flex;
  align-items: center;
  padding: 0.23rem 0.38rem;
  justify-content: space-between;
}
.mymsg {
  margin: 0 5%;
  background-color: #ffffff;
  border-radius: 0.2rem;
  font-size: 0.3rem;
  position: absolute;
  width: 90%;
  padding: 0.5rem 0;
  top: 5rem;
}
.mones {
  text-align: center;
}
.mones > div:nth-child(2) {
  font-size: 0.32rem;
  margin-top: 0.07rem;
}
.incomeclass {
  display: flex;
  color: #ffffff;
  font-size: 0.26rem;
  margin: 0.5rem 0.65rem;
  justify-content: space-between;
}
.mon {
  font-size: 0.4rem;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  margin-top: 0.13rem;
}
.topmsg {
  height: 5.3rem;
  background-image: url("../../assets/redimg.png");
  background-size: 100% 100%;
  padding-top: 0.61rem;
  position: relative;
}
.moneyclass {
  font-size: 0.32rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.moneyclass img {
  width: 0.4rem;
  height: 0.31rem;
  margin-right: 0.32rem;
}
</style>